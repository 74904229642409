import s from "./Ranking.module.css";
import React, { useEffect, useState } from "react";
import { format, dayjs } from "dayjs";
import {
  getTableRanking,
  getProvRanking,
  getExpiredRanking,
} from "../../../api/saaservices/ranking";
import {
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Table,
  TableCell,
  MenuItem,
  Select,
  TextField,
  Box,
  Stack,
  Pagination,
} from "@mui/material";
import { averageFormat } from "../../../helpers/currencyConvert";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material/";
import { createRow } from "../../../helpers/tableFunctions";
import { useSelector, useDispatch } from "react-redux";
import { today, lastWeek, PrimerDiaDelMes } from "../../../helpers/today";
import { getImputation } from "../../../api/accounting/imputation";
import { imputationUpdate } from "../../../store/slices/gammaSlice";
import { getGameOffers } from "../../../api/gamma";
import { useLocation } from "react-router-dom";
import { t } from "i18next";
import { callAgeniesById } from "../../../store/slices/my_agencies.js";
import FormControl from "@mui/material/FormControl";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircularProgress from "@mui/material/CircularProgress";
import StarIcon from "@mui/icons-material/Star";
import ModalProviders from "./ModalProviders/index";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import { setSelectedAgencieToConsult } from "../../../store/slices/my_agencies";
import Loader from "../../Shared/Loader";
import { homologatedGames } from "../../../store/slices/gammaSlice";
import { getHomologatedGames } from "../../../api/general/generalService";
import { getGamesSaa } from "../../../api/games/GamesService.js";
import {
  Number,
  Quantity,
  Currency,
  currencyFormat,
  numberTwoDecimals,
} from "../../../helpers/currencyConvert";
import { getHomologationForName } from "../../../helpers/games";
import Swal from "sweetalert2";
import { exportExcel } from "../../../helpers/exportExcel";
import DescriptionIcon from "@mui/icons-material/Description";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import printTable from "../../../helpers/imprimir";
import { updateLocation } from "../../../store/slices/localSlice";
import { getOrganizationId } from "../../../utils/session.utils.js";
import { getPrintLogo } from "../../../api/themes/ThemeService";

export const Ranking = () => {
  //probando lo que llega de los servicios
  const [isReady, setIsReady] = useState(true);
  const [printing, setPrinting] = useState(false);
  const [homologated_Games, setHomologated_Games] = useState([]);
  const { currentLogo } = useSelector((state) => state.local);
  const { logged_user } = useSelector((state) => state?.gamma);
  const [primaryColor, setPrimaryColor] = useState("");
  const { uiConfig } = useSelector((state) => state.local);
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState(false);
  const [searching, setSearching] = useState(false);
  const [gamesData, setGamesData] = useState([]);
  const agencies = useSelector((state) => state?.myAgencies?.user_agencies);
  const { activeOrganization } = useSelector((state) => state.gamma);
  const { textColor } = useSelector((state) => state.local);
  const [loading, setLoading] = useState(false);
  const { imputations } = useSelector((state) => state.gamma);
  const [table, setTable] = useState([]);
  const [page, setPage] = useState(1);
  const [customPage, setCustomPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState(30);
  const [filtro, setFiltro] = useState({
    from: PrimerDiaDelMes(),
    to: today(),
    game: -1,
    maquina: true,
  });
  const [order, setOrder] = useState(1);
  const dispatch = useDispatch();
  const { formatCurrency } = currencyFormat();
  const [logoImpresion, setLogoImpresion] = useState();

  const tableElementsStyles = {
    fontFamily: "Roboto, sans-serif",
    fontSize: "0.72rem",
    borderBottom: "1px solid rgba(0, 0, 0, 0.455)",
    cursor: "pointer",
  };
  const agencySelected = useSelector(
    (state) => state.myAgencies?.selected_agencie_to_consult,
  );
  useEffect(() => {
    dispatch(updateLocation({ ranking: true }));
  }, []);

  useEffect(() => {
    getGame();
  }, []);

  useEffect(() => {
    if (filtro.from && filtro.to) {
      loadRankingTable();
    }
  },[]);

  useEffect(()=> {
    getLogoImpresion();
  },[]);

  useEffect(() => {
    if (printing === true) {
      printTable(document.getElementById("raking-table"));
    }
    setPrinting(false);
  }, [printing]);

  const headerStyles = {
    ...tableElementsStyles,
    textAlign: "center",
  };

  const cellStyles = {
    ...tableElementsStyles,
    textAlign: "right",
  };

  const headers = [
    t("pdv"),
    t("spdv"),
    t("maq"),
    t("name"),
    t("cant"),
    t("cant"),
    t("average"),
    t("cant"),
    t("average"),
    t("total"),
    t("average"),
    t("total"),
    t("average"),
  ];

  const headersWithOutMachine = [
    t("agencyREAL"),
    t("subagency"),
    t("name"),
    t("amount"),
    t("amount"),
    t("average"),
    t("amount"),
    t("average"),
    t("total"),
    t("average"),
    t("total"),
    t("average"),
  ];

  function mapToSaaOrganization(organizationId) {
    // TODO: incorporar llamado a mapeador de organizations de gamma a saa
    return organizationId === 1 ? 23 : organizationId;
  }

  async function loadGameDataForNames() {
    await getGameOffers(activeOrganization?.id, true, 1, 1000).then((data) =>
      setGamesData(data?.content || []),
    );
  }

  async function fetchTable(agencie, subAgencie) {
    try {
      let rankTable = await getTableRanking(
        mapToSaaOrganization(activeOrganization.id),
        agencie,
        subAgencie,
        filtro.game,
        filtro.from.replace(/\//g, ""),
        filtro.to.replace(/\//g, ""),
        filtro.maquina,
      );
      return rankTable;
    } catch (e) {
      console.log(e);
    }
  }

  async function fetchAgency(agencie) {
    try {
      const result = await fetchTable(
        agencie.number,
        agencie.agencyLevel === "CONCENTRATOR" ? -1 : agencie.subNumber || 0,
      );
      return Array.isArray(result) ? result : [];
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  async function fetchAgencies(agencies) {
    if (agencies && Array.isArray(agencies)) {
      const allAgencies = agencies.map((curAgencie) => fetchAgency(curAgencie));
      return (await Promise.all(allAgencies)).flat();
    } else {
      return [];
    }
  }

  async function loadRankingTable() {
    setLoading(true);
    loadGameDataForNames().then(() => {
      fetchAgencies(agencies)
        .then((res) => setTable(res || []))
        .then(() => {
          setLoading(false);
          setSearch(false);
        });
    });
  }

  // if (!imputations?.length) {
  //   getImputation().then(res => {
  //     const regex = new RegExp('^old');
  //     const result = res.content.filter((r) => regex.test(r.name) === false);
  //     dispatch(imputationUpdate(result));
  //   })
  // }

  useEffect(() => {
    if (search) {
      loadRankingTable();
    }
  }, [search]);

  useEffect(() => {
    setPrimaryColor(uiConfig.primary.main);
  }, [uiConfig]);

  useEffect(() => {
    const ids = [];
    const traverse = logged_user?.memberships[0].agencies?.map((agencie) =>
      ids.push(agencie),
    );
    dispatch(callAgeniesById(ids, agencySelected));
  }, [agencySelected, dispatch, logged_user?.memberships]);

  const fechExpired = async (agencie) => {
    try {
      let exp = await getExpiredRanking(
        activeOrganization.id === 9 ? 23 : activeOrganization.id,
        agencie.number,
        0,
        filtro.from,
        filtro.to,
      );
      return exp;
    } catch (e) {
      console.log(e);
    }
  };

  const isRowComplete = (row) => {
    return (
      row.agente !== undefined &&
      row.subAgente !== undefined &&
      row.maquina !== undefined &&
      row.juego !== undefined &&
      row.sorteos !== undefined &&
      row.cupones !== undefined &&
      row.recaudacion !== undefined &&
      row.apuestas !== undefined &&
      row.promedioCupones !== undefined &&
      row.promedioApuestas !== undefined &&
      row.promedioImporte !== undefined &&
      row.promedioPremios !== undefined
    );
  };

  const groupByGame = (table) => {
    return table.reduce((groups, row) => {
      const gameNames = Array.isArray(gamesData)
        ? gamesData
            .filter((g) => g.id === row.juego)
            .map((g) => g.game?.name)
            .filter((g) => g !== null)
        : [];
      const gameName =
        gameNames.length > 0 && gameNames[0]
          ? gameNames[0]
          : "Juego " + row.juego;
      if (!groups[row.juego]) {
        groups[row.juego] = [];
      }
      groups[row.juego].push({ ...row, nombreJuego: gameName });
      return groups;
    }, []);
  };

  // const mapRankingWithoutMachines = (table) => {
  //   const mappedTable = mapRankingWithMachines(table);
  //   const tableGrouped = mappedTable.reduce((currentTable, currentRow) => {
  //     const codAgencia = toString(currentRow.agencia) + "-" + toString(currentRow.subAgencia);
  //     if(!currentTable[codAgencia])
  //       currentTable[codAgencia] = [];
  //     currentTable[codAgencia].push(currentRow);
  //     return currentTable;
  //   }, {});
  //   const tableWithoutMachines = tableGrouped.map(row => {
  //     return isRowComplete(row) ? {
  //       agencia: row[0].agencia,
  //       subAgencia: row[0].subAgencia,
  //       maquina: null,
  //       juego: row[0].juego,
  //       sorteos: row.reduce((suma, { sorteos }) => suma + sorteos, 0),
  //       cantidadCupones: row.reduce((suma, { cantidadCupones }) => suma + cantidadCupones, 0),
  //       totalRecaudado: row.reduce((suma, { totalRecaudado }) => suma + totalRecaudado, 0.0).toFixed(2),
  //       premios: {
  //         totalPremios: row.reduce((suma, rank) => suma + rank.premios.totalPremios, 0.0).toFixed(2),
  //         cantidadApuestas: row.reduce((suma, rank) => suma + rank.premios.cantidadApuestas, 0)
  //       },
  //       promedioCupones: row.reduce((suma, { promedioCupones }) => suma + promedioCupones, 0.0).toFixed(2),
  //       promedioApuestas: row.reduce((suma, { promedioApuestas }) => suma + promedioApuestas, 0.0).toFixed(2),
  //       promedioImporte: row.reduce((suma, { promedioImporte }) => suma + promedioImporte, 0.0).toFixed(2),
  //       promedioPremios: row.reduce((suma, { promedioPremios }) => suma + promedioPremios, 0.0).toFixed(2),
  //       maxImpDiario: 0
  //     } : null;
  //   }).filter(row => row !== null);
  //   return tableWithoutMachines;
  // }

  const mapRankingWithMachines = (table) => {
    return table
      .map((row) => {
        return isRowComplete(row)
          ? {
              agencia: row.agente,
              subAgencia: row.subAgente,
              maquina: row.maquina,
              juego: row.juego,
              sorteos: row.sorteos,
              cantidadCupones: row.cupones,
              totalRecaudado: row.recaudacion,
              premios: {
                totalPremios: row.premios,
                cantidadApuestas: row.apuestas,
              },
              promedioCupones: row.promedioCupones,
              promedioApuestas: row.promedioApuestas,
              promedioImporte: row.promedioImporte,
              promedioPremios: row.promedioPremios,
              maxImpDiario: 0.0,
            }
          : null;
      })
      .filter((row) => row !== null);
  };

  const createRanking = (table) => {
    let t = mapRankingWithMachines(table);
    let o = ordenarMultiNivel(t);
    // switch (order) {
    //   case 1:
    //     o = ordenarAgencia(t)
    //     break;
    //   case 2:
    //     o = ordenarSubAgencia(t)
    //     break;
    //   case 3:
    //     o = ordenarMachina(t)
    //     break;
    //   default:
    //     o = t
    //     break;
    // }
    return o;
  };

  const ordenarAgencia = (ranking) => {
    return ranking.sort((a, b) => a.agencia - b.agencia);
  };

  const ordenarSubAgencia = (ranking) => {
    return ranking.sort((a, b) => a.subAgencia - b.subAgencia);
  };

  const ordenarMachina = (ranking) => {
    return ranking.sort((a, b) => a.maquina - b.maquina);
  };

  const ordenarMultiNivel = (ranking) => {
    return ranking.sort((a, b) =>
      a.agencia === b.agencia
        ? a.subAgencia === b.subAgencia
          ? a.maquina === b.maquina
            ? a.juego - b.juego
            : a.maquina - b.maquina
          : a.subAgencia - b.subAgencia
        : a.agencia - b.agencia,
    );
  };

  const homologateName = (id) => {
    return getHomologationForName(id, homologated_Games);
  };

  const excelItem = () => {
    let excelData = [];
    console.log("LISTA RANKING : ", createRanking(table));
    createRanking(table).forEach((ranking) => {
      const itemData = {};
      itemData[t("agencyREAL")] = ranking.agencia;
      itemData[t("subagency")] = ranking.subAgencia;
      if (filtro.maquina === true) {
        itemData[t("machine")] = ranking.maquina;
      }
      itemData[t("name")] = homologateName(ranking.juego);
      itemData[t("amount") + " sorteos"] = ranking.sorteos;
      itemData[t("amount") + " cupones"] = ranking.cantidadCupones;
      itemData[t("average") + " cupones"] = numberTwoDecimals(
        ranking.promedioCupones,
      );
      itemData[t("amount") + " apuestas"] = ranking.premios.cantidadApuestas;
      itemData[t("average") + " apuestas"] = numberTwoDecimals(
        ranking.promedioApuestas,
      );
      itemData[t("total") + " recaudado"] = numberTwoDecimals(
        ranking.totalRecaudado,
      );
      itemData[t("average") + " recaudado"] = numberTwoDecimals(
        ranking.promedioImporte,
      );
      itemData[t("total") + " premios"] = numberTwoDecimals(
        ranking.premios.totalPremios,
      );
      itemData[t("average") + " premios"] = numberTwoDecimals(
        ranking.promedioPremios,
      );
      excelData.push(itemData);
    });
    return excelData;
  };

  const excelDownload = () => {
    Swal.fire({
      title: t("excelConfirm"),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      cancelButtonText: t("cancel"),
      confirmButtonText: t("accept"),
    }).then((result) => {
      if (result.isConfirmed) {
        let today = new Date();
        exportExcel(excelItem(), `Ranking - ${today.toISOString()}`);
      }
    });
  };

  function getGame() {
    // getHomologatedGames()
      // .then((r) =>
      //   r.filter(
      //     (juego) =>
      //       juego.integration[0].organization.id ===
      //         agencies[0].organization.id &&
      //       juego.integration[0].module === "SAA",
      //   ),
      // )
      getGamesSaa()
      .then((res) => {
        // res.sort((a, b) => {
        //   const nameA = a.name.toUpperCase();
        //   const nameB = b.name.toUpperCase();
        //   if (nameA < nameB) {
        //     return -1;
        //   }
        //   if (nameA > nameB) {
        //     return 1;
        //   }
        //   return 0;
        // });
        setHomologated_Games(res);
      })
      .catch((error) => console.log("Error"));
  }

  const filterGameName = () => {
    if (filtro.game === -1) {
      return "Todos";
    }  
    const arr = homologated_Games.find((h) => h?.juego === filtro?.game);
    console.log("arr", arr);
    return arr.descripcionJuego;
  };

  function getLogoImpresion() {
    getPrintLogo()
      .then((res) => {
        setLogoImpresion(res);
      })
      .catch((error) => console.log("Error"));
  }

  return isReady ? (
    <Grid container className={s.container}>
      <Grid item container xs={11} className={s.header}>
        <Grid item lg={6} md={6} xs={12} className={s.titleContainer}>
          <StarIcon sx={{ fontSize: "1.8rem", color: "primary.main" }} />
          <h4>{t("ranking")}</h4>
        </Grid>

        <Grid item xs={12} md={6} lg={6} className={s.filters}>
          <Button
            variant="contained"
            className={s.filterButton}
            onClick={() => setOpenModal(true)}
          >
            <TuneRoundedIcon
              sx={{ color: "secondary.light", fontSize: "1.8rem" }}
            />
            <p style={{ fontSize: "0.9rem" }}>{t("filter")}</p>
          </Button>
          {searching && (
            <Button
              variant="contained"
              sx={{ ml: 1 }}
              className={s.filterButton}
              onClick={() => {
                setFiltro((prevState) => ({ ...prevState, game: 0 }));
                setFiltro((prevState) => ({ ...prevState, maquina: true }));
                setSearching(false);
                setSearch(false);
                setTable([]);
              }}
            >
              <p>{t("cleanFilters")}</p>
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid
        item
        container
        xs={11}
        sx={{ mt: 3, justifyContent: "space-between" }}
        className={s.optionsContainer}
      >
        <Grid item container xs={12} sx={{ justifyContent: "flex-end" }}>
          <Button
            disabled={!table?.length}
            size="small"
            variant="contained"
            sx={{ mr: 1, height: "2.5rem" }}
            className={s.optionButton}
            onClick={() => setPrinting(true)}
          >
            <PrintOutlinedIcon
              sx={{ fontSize: "1.5rem", color: textColor }}
              className={s.iconOptions}
            />
          </Button>
          <Button
            disabled={!table?.length}
            size="small"
            variant="contained"
            className={s.optionsContainer}
            onClick={() => excelDownload()}
            sx={{ height: "2.5rem" }}
          >
            <DescriptionIcon
              sx={{ fontSize: "1.5rem", color: textColor }}
              className={s.iconOptions}
            />
          </Button>
        </Grid>
      </Grid>

      <Grid
        item
        container
        xs={11}
        sx={{ mt: 3, justifyContent: "flex-start", mb: 3 }}
      >
        <p style={{ margin: "0 5px 0 0", fontSize: "1rem", fontWeight: 600 }}>
          {t("date")}:
        </p>
        <p style={{ margin: "0 2px 0 0", fontSize: "1rem", fontWeight: 300 }}>
          {filtro?.from} al{" "}
        </p>
        <p style={{ margin: "0 0 0 0", fontSize: "1rem", fontWeight: 300 }}>
          {filtro?.to}
        </p>
      </Grid>

      {loading ? (
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <CircularProgress size="3rem" />
        </Grid>
      ) : (
        <Grid item lg={12} xs={12} display="flex" justifyContent="center">
          {Array.isArray(table) && table.length ? (
            <Grid id="raking-table" style={{ width: "80%" }}>
              {printing === true ? (
                <div>
                  {logoImpresion?.imgBase64.length ? (
                      <img className={s.headerLogo} style={{height:"3rem"}} src={logoImpresion?.imgBase64} alt="logo" />
                  ) : (
                    <></>
                  )}
                  <Grid>
                    <h4 className="texto">{t("ranking")}</h4>
                    <h4 className="texto">
                      {t("date")}: {filtro?.from} al {filtro?.to}
                    </h4>
                    <h4 className="texto">
                      {t("game")}: {filterGameName()}
                    </h4>
                  </Grid>
                </div>
              ) : (
                <></>
              )}
              <TableContainer className={s.tableContainer}>
                <Table stickyHeader className="print-table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        colSpan={filtro.maquina === true ? 3 : 2}
                        sx={{
                          backgroundColor: "primary.main",
                          color: textColor,
                          borderInline: "0.5px solid rgba(0, 0, 0, 0.455)",
                          p: 1.6,
                          ...headerStyles,
                        }}
                      >
                        {t("agencie")}
                      </TableCell>
                      <TableCell
                        colSpan={1}
                        sx={{
                          fontWeight: 500,
                          backgroundColor: "primary.main",
                          color: textColor,
                          borderInline: "0.5px solid rgba(0, 0, 0, 0.455)",
                          p: 1.6,
                          ...headerStyles,
                        }}
                      >
                        {t("game")}
                      </TableCell>
                      <TableCell
                        colSpan={1}
                        sx={{
                          fontWeight: 500,
                          backgroundColor: "primary.main",
                          color: textColor,
                          borderInline: "0.5px solid rgba(0, 0, 0, 0.455)",
                          p: 1.6,
                          ...headerStyles,
                        }}
                      >
                        {t("raffles")}
                      </TableCell>
                      <TableCell
                        colSpan={2}
                        sx={{
                          fontWeight: 500,
                          backgroundColor: "primary.main",
                          color: textColor,
                          borderInline: "0.5px solid rgba(0, 0, 0, 0.455)",
                          p: 1.6,
                          ...headerStyles,
                        }}
                      >
                        {t("coupons")}
                      </TableCell>
                      <TableCell
                        colSpan={2}
                        sx={{
                          fontWeight: 500,
                          backgroundColor: "primary.main",
                          color: textColor,
                          borderInline: "0.5px solid rgba(0, 0, 0, 0.455)",
                          p: 1.6,
                          ...headerStyles,
                        }}
                      >
                        {t("bet")}
                      </TableCell>
                      <TableCell
                        colSpan={2}
                        sx={{
                          fontWeight: 500,
                          backgroundColor: "primary.main",
                          color: textColor,
                          borderInline: "0.5px solid rgba(0, 0, 0, 0.455)",
                          p: 1.6,
                          ...headerStyles,
                        }}
                      >
                        {t("collection")}
                      </TableCell>
                      <TableCell
                        colSpan={2}
                        sx={{
                          fontWeight: 500,
                          backgroundColor: "primary.main",
                          color: textColor,
                          borderInline: "0.5px solid rgba(0, 0, 0, 0.455)",
                          p: 1.6,
                          ...headerStyles,
                        }}
                      >
                        {t("prizes")}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      {filtro.maquina === true
                        ? headers.map((head, i) => (
                            <TableCell
                              style={{ top: "49px" }}
                              sx={{
                                backgroundColor: "primary.dark",
                                borderInline:
                                  "0.5px solid rgba(0, 0, 0, 0.455)",
                                p: 1.6,
                                color: textColor,
                                ...headerStyles,
                              }}
                              key={i}
                            >
                              {head}
                            </TableCell>
                          ))
                        : headersWithOutMachine.map((head, i) => (
                            <TableCell
                              style={{ top: "49px" }}
                              sx={{
                                backgroundColor: "primary.dark",
                                borderInline:
                                  "0.5px solid rgba(0, 0, 0, 0.455)",
                                p: 1.6,
                                color: textColor,
                                ...headerStyles,
                              }}
                              key={i}
                            >
                              {head}
                            </TableCell>
                          ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {createRanking(table).map((ranking, i) => {
                      return (
                        <TableRow
                          key={i}
                          sx={{
                            "&:hover": { backgroundColor: "secondary.light" },
                          }}
                        >
                          <TableCell
                            className="alinear-centro"
                            sx={{
                              ...cellStyles,
                              backgroundColor:
                                i % 2 ? "#dbdbdb76" : "#dbdbdd12",
                            }}
                          >
                            {ranking.agencia}
                          </TableCell>
                          <TableCell
                            className="alinear-centro"
                            sx={{
                              ...cellStyles,
                              backgroundColor:
                                i % 2 ? "#dbdbdb76" : "#dbdbdd12",
                            }}
                          >
                            {ranking.subAgencia}
                          </TableCell>
                          {filtro.maquina === true ? (
                            <TableCell
                              className="alinear-centro"
                              sx={{
                                ...cellStyles,
                                backgroundColor:
                                  i % 2 ? "#dbdbdb76" : "#dbdbdd12",
                              }}
                            >
                              {ranking.maquina}
                            </TableCell>
                          ) : null}
                          <TableCell
                            className="alinear-centro"
                            sx={{
                              ...headerStyles,
                              backgroundColor:
                                i % 2 ? "#dbdbdb76" : "#dbdbdd12",
                            }}
                          >
                            {homologateName(ranking.juego)}
                          </TableCell>
                          <TableCell
                            className="alinear-derecha"
                            sx={{
                              ...cellStyles,
                              backgroundColor:
                                i % 2 ? "#dbdbdb76" : "#dbdbdd12",
                            }}
                          >
                            <Quantity value={ranking.sorteos} />
                          </TableCell>
                          <TableCell
                            className="alinear-derecha"
                            sx={{
                              ...cellStyles,
                              backgroundColor:
                                i % 2 ? "#dbdbdb76" : "#dbdbdd12",
                            }}
                          >
                            <Quantity value={ranking.cantidadCupones} />
                          </TableCell>
                          <TableCell
                            className="alinear-derecha"
                            sx={{
                              ...cellStyles,
                              backgroundColor:
                                i % 2 ? "#dbdbdb76" : "#dbdbdd12",
                            }}
                          >
                            <Number value={ranking.promedioCupones} />
                          </TableCell>
                          <TableCell
                            className="alinear-derecha"
                            sx={{
                              ...cellStyles,
                              backgroundColor:
                                i % 2 ? "#dbdbdb76" : "#dbdbdd12",
                            }}
                          >
                            <Quantity
                              value={ranking.premios.cantidadApuestas}
                            />
                          </TableCell>
                          <TableCell
                            className="alinear-derecha"
                            sx={{
                              ...cellStyles,
                              backgroundColor:
                                i % 2 ? "#dbdbdb76" : "#dbdbdd12",
                            }}
                          >
                            <Number value={ranking.promedioApuestas} />
                          </TableCell>
                          <TableCell
                            className="alinear-derecha"
                            sx={{
                              ...cellStyles,
                              backgroundColor:
                                i % 2 ? "#dbdbdb76" : "#dbdbdd12",
                            }}
                            align="right"
                          >
                            {" "}
                            {formatCurrency(ranking.totalRecaudado)}
                          </TableCell>
                          <TableCell
                            className="alinear-derecha"
                            sx={{
                              ...cellStyles,
                              backgroundColor:
                                i % 2 ? "#dbdbdb76" : "#dbdbdd12",
                            }}
                            align="right"
                          >
                            {formatCurrency(ranking.promedioImporte)}
                          </TableCell>
                          <TableCell
                            className="alinear-derecha"
                            sx={{
                              ...cellStyles,
                              backgroundColor:
                                i % 2 ? "#dbdbdb76" : "#dbdbdd12",
                            }}
                            align="right"
                          >
                            {formatCurrency(ranking.premios.totalPremios)}
                          </TableCell>
                          <TableCell
                            className="alinear-derecha"
                            sx={{
                              ...cellStyles,
                              backgroundColor:
                                i % 2 ? "#dbdbdb76" : "#dbdbdd12",
                            }}
                            align="right"
                          >
                            {formatCurrency(ranking.promedioPremios)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <TableBody></TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              sx={{
                minHeight: "300px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "1.8rem",
                fontFamily: "Roboto, sans-serif",
                fontWeight: 300,
              }}
            >
              {t("noResults")}
            </Grid>
          )}
        </Grid>
      )}
      <ModalProviders
        open={openModal}
        setOpen={setOpenModal}
        setFilters={setFiltro}
        filters={filtro}
        setSearching={setSearching}
        setSearch={setSearch}
        setOrder={setOrder}
        setLoading={setLoading}
        inputFormat="DD/MM/YYYY"
      />
    </Grid>
  ) : (
    <Grid container className={s.container}>
      <Grid item container xs={11} className={s.header}>
        <Grid item lg={6} md={6} xs={12} className={s.titleContainer}>
          <h4>Próximamente.</h4>
        </Grid>
      </Grid>
    </Grid>
  );
};

{
  /* <Grid item container xs={7} sx={{ justifyContent: 'space-evenly', alignItems: 'center', mb: 5, mt: 5 }}>
<Select disabled={!sales?.length} value={perPage} onChange={(e) => handleChangePerPage(e.target.value)}>
  <MenuItem value={10}>10</MenuItem>
  <MenuItem value={20}>20</MenuItem>
  <MenuItem value={50}>50</MenuItem>
</Select>
<Stack spacing={2}>
  <Pagination
    showFirstButton
    showLastButton
    count={(sales.length / perPage).toFixed(0) }
    page={page}
    onChange={handlePagination}
    color={"secondary"}
    disabled={!sales?.length}
  />
</Stack>
//</Grid><form style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", width: '120px' }} onSubmit={e => e.preventDefault()}>
//<TextField disabled={!sales?.length} type={'number'} value={customPage} onChange={(e) => setCustomPage(e.target.value)} sx={{ width: '100%', mr: '5px' }} />
//<Button disabled={!sales?.length} variant='contained' onClick={() => {
//if (0 <= customPage && customPage <= totalPages) {
  //    setPage(customPage * 1);
  //  } else {
  //    setCustomPage(page);
 //   }
//</form>  }}>Ir</Button>
//</form>
//</Grid> */
}
