import apiClient from "../../utils/api.client";
import { getOrganizationId } from "../../utils/session.utils";

/*AGREGAR ORGID*/
export const getAllThemes = async (organizationId = getOrganizationId()) => {
  try {
    const response = await apiClient.get(
      `/api-themes/themes/pallete?organizationId=${organizationId}`,
    );
    const data = response.data;

    return data;
  } catch (error) {
    console.error("Error at getAllThemes EP: ", error);
    throw error;
  }
};

export const currentTheme = async (organizationid = getOrganizationId()) => {
  try {
    const response = await apiClient.get(
      `/api-themes/themes/pallete/getCurrentThemes/${organizationid}`,
    );
    const data = response.data;

    return data;
  } catch (error) {
    console.error("Error at currentTheme EP: ", error);
    throw error;
  }
};

export const addNewTheme = async (payload) => {
  try {
    const response = await apiClient.post(
      `/api-themes/themes/pallete`,
      payload,
    );
    const data = response.data;

    return data;
  } catch (error) {
    console.error("Error at addNewTheme EP: ", error);
    throw error;
  }
};

export const editTheme = async (payload) => {
  try {
    const response = await apiClient.put(`/api-themes/themes/pallete`, payload);
    const data = response.data;

    return data;
  } catch (error) {
    console.error("Error at editTheme EP: ", error);
    throw error;
  }
};

export const deleteTheme = async (themeId) => {
  try {
    const response = await apiClient.delete(
      `/api-themes/themes/pallete/${themeId}`,
    );
    const data = response.data;

    return data;
  } catch (error) {
    console.error("Error at deleteTheme EP: ", error);
    throw error;
  }
};

export const setDefaultTheme = async (
  themeId,
  organizationId = getOrganizationId(),
) => {
  let payload = {
    id: themeId,
    organizationId: organizationId,
    defaultP: true,
  };

  try {
    const response = await apiClient.post(
      `/api-themes/themes/pallete/setdefault`,
      payload,
    );
    const data = response.data;

    return data;
  } catch (error) {
    console.error("Error at setDefaultTheme EP: ", error);
    throw error;
  }
};

export const setCurrentTheme = async (
  themeId,
  organizationId = getOrganizationId(),
) => {
  let payload = {
    id: themeId,
    organizationId: organizationId,
    current: true,
  };
  try {
    const response = await apiClient.post(
      `/api-themes/themes/pallete/setcurrent`,
      payload,
    );
    const data = response.data;

    return data;
  } catch (error) {
    console.error("Error at setCurrentTheme EP: ", error);
    throw error;
  }
};

/*AGREGAR ORGANIZATIONID*/
export const getAllLogos = async (organizationId = getOrganizationId()) => {
  try {
    const response = await apiClient.get(
      `/api-themes/themes/logo?organizationId=${organizationId}`,
    );
    const data = response.data;

    return data;
  } catch (error) {
    console.error("Error at getAllLogo EP: ", error);
    throw error;
  }
};

export const addNewLogo = async (payload) => {
  try {
    const response = await apiClient.post(`/api-themes/themes/logo`, payload);
    const data = response.data;

    return data;
  } catch (error) {
    console.error("Error at addNewLogo EP: ", error);
    throw error;
  }
};

export const editLogo = async (payload) => {
  try {
    const response = await apiClient.put(`/api-themes/themes/logo`, payload);
    const data = response.data;

    return data;
  } catch (error) {
    console.error("Error at editLogo EP: ", error);
    throw error;
  }
};

export const deleteLogo = async (logoId) => {
  try {
    const response = await apiClient.delete(
      `/api-themes/themes/logo/${logoId}`,
    );
    const data = response.data;

    return data;
  } catch (error) {
    console.error("Error at deleteLogo EP: ", error);
    throw error;
  }
};

export const currentLogo = async (organizationid = getOrganizationId()) => {
  try {
    const response = await apiClient.get(
      `/api-themes/themes/logo/getCurrentLogo/${organizationid}`,
    );
    const data = response.data;

    return data;
  } catch (error) {
    console.error("Error at currentLogo EP: ", error);
    throw error;
  }
};

export const getPrintLogo = async (organizationid = getOrganizationId()) => {
  try {
    const response = await apiClient.get(
      `/api-themes/themes/logo/getImpressionLogo/${organizationid}`,
    );
    const data = response.data;

    return data;
  } catch (error) {
    console.error("Error at getPrintLogo EP: ", error);
    throw error;
  }
};

export const logoBaseOnOrgId = async (organizationid) => {
  try {
    const response = await fetch(
      `/${organizationid}/api-themes/themes/logo/getCurrentLogo/${organizationid}`,
    );
    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error at logoBaseOnOrgId EP: ", error);
    throw error;
  }
};

export const setDefaultLogo = async (
  logoId,
  organizationId = getOrganizationId(),
) => {
  let payload = {
    id: logoId,
    organizationId: organizationId,
    defaultL: true,
  };

  try {
    const response = await apiClient.post(
      `/api-themes/themes/logo/setdefault`,
      payload,
    );
    const data = response.data;

    return data;
  } catch (error) {
    console.error("Error at setDefaultLogo EP: ", error);
    throw error;
  }
};

export const setCurrentLogo = async (
  logoId,
  organizationId = getOrganizationId(),
) => {
  let payload = {
    id: logoId,
    organizationId: organizationId,
    current: true,
  };
  try {
    const response = await apiClient.post(
      `/api-themes/themes/logo/setcurrent`,
      payload,
    );
    const data = response.data;

    return data;
  } catch (error) {
    console.error("Error at setDefaultLogo EP: ", error);
    throw error;
  }
};
