import apiClient from "../../utils/api.client";
import { getOrganizationId } from "../../utils/session.utils";

export async function getAllConfigurations(
  organizationId = getOrganizationId(),
) {
  try {
    const path = `/api-configuration/settings/config?organizationId=${organizationId}&integrations=GAMMA`;
    const response = await apiClient.get(path);
    return response.data;
  } catch (error) {
    console.error("Error at getAllConfigurations EP: ", error);
    if (error instanceof Error) {
      throw error;
    } else {
      throw new Error(`Error at getAllConfigurations EP: ${error}`);
    }
  }
}

export async function getOrganizationPackage(
  organizationId = getOrganizationId(),
) {
  const path = `api-configuration/settings/organization?organizationId=${organizationId}&integrations=GAMMA`;

  try {
    const response = await apiClient.get(path);
    return response.data;
  } catch (error) {
    console.error("Error at getAllConfigurations EP: ", error);
    if (error instanceof Error) {
      throw error;
    } else {
      throw new Error(`Error at getAllConfigurations EP: ${error}`);
    }
  }
}

export async function createConfigItem(payload) {
  const path = `/api-configuration/settings/config/create`;
  try {
    const response = await apiClient.post(path, payload);
    console.log(response);

    return response.data;
  } catch (error) {
    console.error("Error at createConfigItem EP", error);
    if (error instanceof Error) {
      throw error;
    } else {
      throw new Error(`Error at createConfigItem EP ${error}`);
    }
  }

  // try {
  //   const response = await fetch(`/api-configuration/settings/config/create`, {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: JSON.stringify(payload)
  //   })
  //   return response;
  // } catch (e) {
  //   console.log(e)
  // }
}

export async function searchConfigItem(
  configKey,
  organizationId = getOrganizationId(),
) {
  // return fetch(`/api-configuration/settings/config/predicted/${configKey}`)
  //   .then(res => res.json())
  //   .then(json => { return json })
  //   .catch((err) => console.log(err));
  try {
    const response = await apiClient.get(
      `/api-configuration/settings/config/predicted/${configKey}?organizationId=${organizationId}`,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function updateConfigItem(
  payload,
  organizationId = getOrganizationId(),
) {
  try {
    const response = await apiClient.put(
      `/api-configuration/settings/config/edit?organizationId=${organizationId}`,
      payload,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function deleteConfigItem(
  configItemId,
  organizationId = getOrganizationId(),
) {
  try {
    const response = await apiClient.delete(
      `/api-configuration/settings/config/delete?id=${configItemId}&organizationId=${organizationId}`,
    );
    console.log("delete config response", response);
    const data = response.data;
    return data;
  } catch (error) {
    console.log(error);
  }
}

// export async function updateConfigItem(payload) {
//   try {
//     const response = await fetch(`/api-configuration/settings/config/edit`, {
//       method: 'PUT',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify(payload)
//     })
//     return response;
//   } catch (e) {
//     console.log(e)
//   }
// }

// export async function deleteConfigItem(configItemId) {
//   try {
//     const response = await fetch(`/api-configuration/settings/config/delete?id=${configItemId}`, {
//       method: 'DELETE',
//       headers: { 'Content-Type': 'application/json' }
//     })
//     console.log("delete config response", response)
//     return response;
//   } catch (e) {
//     console.log(e)
//   }
// }
