import styles from "./index.module.css";
import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState, useEffect } from "react";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import PrintIcon from "@mui/icons-material/Print";
import DescriptionIcon from "@mui/icons-material/Description";
import { useSelector, useDispatch } from "react-redux";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";

import { format, parse } from "date-fns";
import {
  createCtaCteRow,
  createCtaCteRowMoney,
} from "../../../helpers/tableFunctions";
import { movementsUpdate } from "../../../store/slices/gammaSlice";
import { dateInRange } from "../../../helpers/dateInRange";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import usePagination from "./Pagination";
import {
  MovementsByImputation,
  subTotal,
  findImputation,
  groupByImputation,
} from "../../../api/accounting/accountingHelper";
import ModalCC from "./Modal/index";
import { getAgencieReport } from "../../../api/movement/MovementService";
import {
  exportExcel,
  exportExcel2Sheets,
  exportPDF,
  exportToExcel,
} from "../../../helpers/exportExcel";
import Swal from "sweetalert2";
import { Currency, currencyFormat } from "../../../helpers/currencyConvert";
import { Button } from "@mui/material";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import ModalProviders from "./ModalProviders/index";
import printTable from "../../../helpers/imprimir";
import my_agencies, {
  setConcentrator,
  setSelectedAgencieToConsult,
} from "../../../store/slices/my_agencies";
import { useLocation, useNavigate } from "react-router-dom";
import AgencySelectModal from "../../Shared/AgencySelectModal/AgencySelectModal";
import { getAgencieSettlements } from "../../../api/general/generalService";
import CircularProgress from "@mui/material/CircularProgress";
import {
  formatJava,
  formatJavaCompleto,
  formatJavaTiempo,
  minDateRange,
  timeFormat,
} from "../../../config/strings";
import { configDate } from "../../../helpers/convertDate";
import {
  AgencyPage,
  getPresentAgencyInPath,
  logOutPath,
  hasAgencyInPath,
  getAgencyNumberDisplay,
} from "../../../helpers/path";
import { unique } from "../../../helpers/arrays";
import { formatArg } from "../../../config/strings";
import { SignalCellularNull } from "@mui/icons-material";
import { createPrizeRow } from "../../../helpers/tableFunctions";
import { agencieSearchByNumber } from "../../../api/agencies/AgenciesService";
import { PrimerDiaDelMes } from "../../../helpers/today";
import { updateLocation } from "../../../store/slices/localSlice";
import { getPrintLogo } from "../../../api/themes/ThemeService";

export const CuentaCorriente = () => {
  const [message, setMessage] = useState("");
  const [openBank, setOpenBank] = useState(false);
  const { currentLogo } = useSelector((state) => state.local);
  const [openFilter, setOpenFilter] = useState(false);
  // const [dateFrom, setDateFrom] = useState(diaAyer() > minDateRange ? diaAyer() : minDateRange)
  // const [dateTo, setDateTo] = useState(diaHoy())
  const { imputations, organization_Configuration, activeOrganization } =
    useSelector((state) => state.gamma);
  const [cuentaCorriente, setCuentaCorriente] = useState(undefined);
  const agencies = useSelector((state) => state.myAgencies.user_agencies);
  const { textColor } = useSelector((state) => state.local);
  const [search, setSearch] = useState(false);
  const [searchDay, setSearchDay] = useState(false);
  const [searching, setSearching] = useState(false);
  const [print, setPrint] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dateFrom, setDateFrom] = useState(diaAyer());
  const [dateTo, setDateTo] = useState(diaHoy());
  const [loadingAgency, setLoadingAgency] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const agencieSelected = useSelector(
    (state) => state.myAgencies?.selected_agencie_to_consult,
  );
  const concentrator = useSelector(
    (state) => state.myAgencies?.selected_concentrator,
  );

  const [filterParams, setFilterParams] = useState({
    dateFrom: diaAyer(),
    dateTo: diaHoy(),
    game: -1,
    concept: "",
  });
  const { formatLocalDate, formatLocalDateTime } = configDate();
  const location = useLocation();
  const actualPage = useSelector(
    (state) => state.pagination.currentAccountActualPage,
  );
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState();

  const page = usePagination(
    actualPage,
    imputations?.totalPages,
    agencieSelected?.id,
  );
  const handlePagination = (e, p) => {
    page.jump(p);
  };

  const { formatCurrency } = currencyFormat();

  const mensajeError = {
    message: "",
    agencySettlement: null,
    report: null,
  };

  const defaultCuentaCorriente = {
    headers: [],
    movements: [],
  };

  const translationDebitCredit = {
    DEBIT: t("debit"),
    CREDIT: t("credit"),
  };

  const [logoImpresion, setLogoImpresion] = useState();

  useEffect(()=> {
    getLogoImpresion();
  },[]);

  function diaAyer() {
    let d = new Date();
    d.setDate(d.getDate() - 1);
    return format(d, formatJavaCompleto);
  }
  function diaAnterior(d) {
    let day = parse(d, formatJavaCompleto, new Date());
    day.setDate(day.getDate() - 1);
    return format(day, formatJavaCompleto);
  }
  function diaSiguiente(d) {
    let day = parse(d, formatJavaCompleto, new Date());
    day.setDate(day.getDate() + 1);
    return format(day, formatJavaCompleto);
  }

  function diaHoy() {
    return format(new Date(), formatJavaCompleto);
  }

  const dateRange = function (start, end) {
    let arr = [];
    for (
      let dt = parse(start, formatJavaCompleto, new Date());
      dt <= parse(end, formatJavaCompleto, new Date());
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt));
    }
    return arr;
  };

  const formatDay = (date) => {
    return format(date, formatJavaCompleto);
  };

  function buildMovementsFor(content) {
    const flatContent = content
      .map((element) =>
        element.movements
          .map((m) => {
            return { ...m, imputation: element.imputation };
          })
          .flat(),
      )
      .flat();
    return flatContent.map((element) => {
      return {
        gameId: element.imputation.sourceId,
        imputationId: element.imputation.id,
        gameDesc: element.imputation.name,
        imputationDate: element.imputationDate,
        raffle: element.raffle,
        code: element.accountingCode.code,
        codeDesc: element.accountingCode.description,
        kind: element.accountingCode.kind,
        amount:
          element.amount * (element.accountingCode.kind === "DEBIT" ? -1 : 1),
        created: element.created,
        imputationDate: element.imputationDate,
        description: element.accountingCode.description,
        tipo: element.accountingCode.kind,
      };
    });
  }

  function mapToCuentaCorriente(results) {
    if (!results.some((data) => data === undefined)) {
      results = results || [];
      setMessage(results[0]?.message);
      let reports = results.filter(
        (res) => res.agencySettlement !== null && res.report !== null,
      );
      return {
        headers: unique(reports, (r) => r.agencySettlement.id).map((report) => {
          return {
            settlement: report.agencySettlement.settlement,
            obligationsAmountCredit:
              report.agencySettlement.obligationsAmountCredit,
            obligationsAmountDebit:
              report.agencySettlement.obligationsAmountDebit,
            balanceAmountCredit: report.agencySettlement.balanceAmountCredit,
            balanceAmountDebit: report.agencySettlement.balanceAmountDebit,
            settlementNumber: report.agencySettlement.settlementNumber,
            message: report.message,
          };
        }),
        movements: reports
          .map((report) => {
            return buildMovementsFor(report.report.movements.content);
          })
          .flat(),
      };
    }
  }

  async function loadCuentaCorriente(agencie, dateTo, dateFrom) {
    const reports = await Promise.all(
      dateRange(dateFrom, dateTo).map((day) => {
        return loadReport(agencie, day);
      }),
    )
      .then((res) => res.filter((r) => r !== null))
      .catch((error) => console.log(error));
    return mapToCuentaCorriente(reports);
  }

  async function loadReport(agencie, day) {
    const date = formatDay(day);
    try {
      const agenciesReport = await getAgencieReport(
        concentrator,
        date,
        agencie,
      );
      return agenciesReport;
    } catch (error) {
      console.log(error);
    }
  }

  async function MapMovements(agencie, agencieNumber) {
    let number = agencie.number + "-" + agencie.subNumber;
    if (number == agencieNumber.toString()) {
      return await loadCuentaCorriente(
        agencie,
        filterParams.dateTo,
        filterParams.dateFrom,
      );
    }
  }

  async function mapAllAgencies(agencieNumber) {
    const agMap = agencies.map((agencie) => {
      return MapMovements(agencie, agencieNumber);
    });
    return await Promise.all(agMap);
  }

  useEffect(() => {
    dispatch(updateLocation({ cuentacorriente: true }));
  }, []);

  useEffect(() => {
    handleConsulting(agencieSelected);
  }, []);

  useEffect(() => {
    setCuentaCorriente(defaultCuentaCorriente);

    const path = location.pathname.split("/");
    if (hasAgencyInPath(path)) {
      const agency = getPresentAgencyInPath(path, agencies);
      if (agency === null) {
        navigate(logOutPath);
      } else {
        dispatch(setSelectedAgencieToConsult(agency));
        attemptLoadCuentaCorriente();
      }
    }
  }, []);

  useEffect(() => {
    if (print === true) {
      printTable(document.getElementById("table-print"));
    }
    setPrint(false);
  }, [print]);

  useEffect(() => {
    if (search || cuentaCorriente?.movements?.length === 0) {
      attemptLoadCuentaCorriente().then(() => {
        setSearch(false);
      });
    }
  }, [search, agencieSelected, filterParams]);

  async function getLastLiquidacionFirstSearch() {
    if (cuentaCorriente?.movements.length === 0) {
      await setFilterParams((prevState) => ({
        ...prevState,
        dateTo: diaAnterior(filterParams.dateTo),
        dateFrom: diaAnterior(filterParams.dateFrom),
      }));
    }
  }

  async function attemptLoadCuentaCorriente() {
    if (agencieSelected) {
      setLoading(true);
      loadCuentaCorriente(
        agencieSelected,
        filterParams.dateTo,
        filterParams.dateFrom,
      ).then((cc) => {
        if (cc) {
          if (
            cc.movements.length !== 0 ||
            filterParams.dateTo.includes("2023-10-01") ||
            filterParams.dateTo.includes("2023-10-01") ||
            searchDay
          ) {
            setSearchDay(false);
            setCuentaCorriente(cc);
            setLoading(false);
          } else {
            getLastLiquidacionFirstSearch();
          }
        }
      });
    }
  }

  const excelItem = () => {
    const excelDatas = [];
    let excelData1 = [];
    let excelData2 = [];
    const traverse = cuentaCorriente?.headers?.forEach((item) => {
      const itemData = {};
      itemData[t("summary")] = item.settlementNumber;
      itemData[t("expirationDate")] = formatLocalDate(
        item.settlement.settlementEvent.debitBankExpiration1,
        formatJava,
      );
      itemData[t("settlementDay")] = formatLocalDate(
        item.settlement.settlementEvent.eventDate,
        formatJava,
      );
      itemData[t("totalDebit")] = item.obligationsAmountDebit;
      itemData[t("totalCredit")] = item.obligationsAmountCredit;
      itemData[t("balance")] = showSaldo(
        item.balanceAmountCredit,
        item.balanceAmountDebit,
      );
      excelData1.push(itemData);
    });
    const traverseCuentaCorriente = cuentaCorriente?.movements.forEach(
      (movement) => {
        const itemData = {};
        itemData[t("game")] = movement?.gameDesc;
        itemData[t("date")] = formatLocalDateTime(
          movement.created,
          formatJava,
          timeFormat,
        );
        itemData[t("imputationDate")] = formatLocalDateTime(
          movement?.imputationDate,
          formatJava,
          timeFormat,
        );
        itemData[t("type")] = movement.description;
        itemData[t("documentKind")] = movement?.tipo;
        itemData[t("balance")] = movement.amount;
        excelData2.push(itemData);
      },
    );
    excelDatas.push(excelData1, excelData2);

    return excelDatas;
  };

  const excelDownload = () => {
    Swal.fire({
      title: t("excelConfirm"),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      cancelButtonText: t("cancel"),
      confirmButtonText: t("accept"),
    }).then((result) => {
      if (result.isConfirmed) {
        let today = new Date();
        exportExcel2Sheets(
          excelItem(),
          `Movimientos - Cuenta Corriente - ${today.toISOString()}`,
        );
      }
    });
  };

  const handleOpen = () => setOpenBank(true);

  const cellStyles = {
    fontSize: "0.9rem",
    fontWeight: "400",
    borderBottom: "1px solid rgba(0, 0, 0, 0.455)",
  };

  function formatDate(string) {
    if (!string?.length) {
      return "";
    }
    return new Date(string).toLocaleDateString();
  }

  function showHide() {
    setShowModal(!showModal);
  }

  const handleConsulting = (agencie) => {
    if (agencie) {
      if (location.pathname.includes(AgencyPage.CuentaCorriente)) {
        setLoadingAgency(true);
        agencieSearchByNumber(activeOrganization?.id, {
          number: agencie?.number,
        })
          .then((response) => {
            dispatch(setConcentrator(response));
            dispatch(setSelectedAgencieToConsult(agencie));
            setLoadingAgency(false);
          })
          .then(() => {
            navigate(
              `${AgencyPage.CuentaCorriente}/${getAgencyNumberDisplay(agencie)}`,
            );
            setSearch(true);
          })
          .catch((e) => {
            console.log(e);
            setLoadingAgency(false);
          });
      }
    }
  };

  const showSaldo = (credit, debit) => {
    if (debit > 0 && credit == 0) {
      return debit * -1;
    }
    if (credit > 0 && debit == 0) {
      return credit;
    }
    return 0;
  };

  const translateDebitCredit = (value) => {
    return translationDebitCredit[value] || "-";
  };

  const onlyFilteredGroups = (group) => {

    return filterParams?.game !== -1
      ? group[0].imputationId === filterParams?.game
      : true;
  };

  const calculateTotalAmount = (headers) => {
    return Math.abs(
      headers.reduce(
        (acc, item) =>
          acc + showSaldo(item.balanceAmountCredit, item.balanceAmountDebit),
        0,
      ),
    );
  };

  const getConceptos = (movimientos) => {
    return movimientos
      ?.reduce(function (accumulator, curValue) {
        if (
          !(
            (curValue.description.includes("COMISION") &&
              curValue.description !== "COMISION") ||
            (curValue.description.includes("RECAUDACION") &&
              curValue.description !== "RECAUDACION") ||
            (curValue.description.includes("CSA") &&
              curValue.description !== "CSA") ||
            (curValue.description.includes("TISSH INSCRIPTO") &&
              curValue.description !== "TISSH INSCRIPTO") ||
            (curValue.description.includes("RETENCION INGRESOS BRUTOS") &&
              curValue.description !== "RETENCION INGRESOS BRUTOS")
          ) &&
          !accumulator.includes(curValue.description)
        ) {
          accumulator.push(curValue.description);
        }
        return accumulator;
      }, [])
      .sort();
  };

  function getLogoImpresion() {
    getPrintLogo()
      .then((res) => {
        setLogoImpresion(res);
      })
      .catch((error) => console.log("Error"));
  }

  return (
    <Grid container className={styles.container}>
      <Grid item container xs={11} className={styles.header}>
        <Grid item lg={6} md={6} xs={12} className={styles.titleContainer}>
          <AttachMoneyIcon sx={{ fontSize: "1.8rem", color: "primary.main" }} />
          <h4>{t("currentAccount")}</h4>
        </Grid>

        <Grid item xs={12} md={5} lg={5} className={styles.filters}>
          <Button
            disabled={!agencieSelected}
            variant="contained"
            className={styles.filterButton}
            onClick={() => {
              setOpenFilter(true);
            }}
          >
            <TuneRoundedIcon
              sx={{ color: "secondary.light", fontSize: "1.8rem" }}
            />
            <p style={{ fontSize: "0.9rem" }}>{t("filter")}</p>
          </Button>
          {searching ? (
            <Button
              variant="contained"
              sx={{ ml: 1 }}
              className={styles.filterButton}
              onClick={() => {
                setFilterParams({
                  dateFrom: diaHoy(),
                  dateTo: diaHoy(),
                  game: -1,
                  concept: "",
                });
                setCuentaCorriente({ movements: [], headers: [] });
                setSearch(true);
                setSearching(false);
              }}
            >
              <p style={{ fontSize: "0.9rem" }}>{t("cleanFilters")}</p>
            </Button>
          ) : null}
          {loadingAgency ? (
            <Box sx={{ mx: 7 }}>
              <CircularProgress size={"2rem"} />
            </Box>
          ) : (
            <Button
              sx={{ ml: 1 }}
              onClick={showHide}
              color="primary"
              className={styles.filterButton}
              variant="contained"
            >
              <p style={{ fontSize: "0.7rem" }}>{t("selectAgency")}</p>
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid className={styles.selectAgency} item xs={11}>
        <p style={{ fontSize: "1.3rem", fontWeight: "300" }}>
          {agencieSelected?.name
            ? `${t("salePoint")}: (${agencieSelected.number}${
                agencieSelected.subNumber !== null
                  ? " - " + agencieSelected.subNumber
                  : ""
              }) ${agencieSelected.name}`
            : t("agencyNotSelected")}
        </p>
        {showModal && (
          <AgencySelectModal
            open={showModal}
            setOpen={setShowModal}
            onSelect={handleConsulting}
          />
        )}
      </Grid>

      {loading ? (
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <CircularProgress size="3rem" />
        </Grid>
      ) : (
        <>
          <Grid
            container
            item
            xs={12}
            className={styles.content}
            id="table-print"
          >
            {print ? (
              logoImpresion?.imgBase64.length ? (
                  <img className={styles.headerLogo} style={{height:"3rem"}} src={logoImpresion?.imgBase64} alt="logo" />
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
            {print ? (
              <Grid item container xs={12} alignItems="flex-end">
                <h4 className={styles.date}>{t("currentAccount")}</h4>
                <h4 className={`${styles.date} ${"texto"}`}>
                  {t("settlementDay")}:{" "}
                  <span className={`${styles.inputationDate} ${"texto"}`}>
                    {cuentaCorriente?.movements?.length === 0 ? (
                      <>
                        {" "}
                        {formatDate(filterParams?.dateFrom)}{" "}
                        {filterParams?.dateFrom.length &&
                        filterParams?.dateTo.length
                          ? "al"
                          : "-"}{" "}
                        {formatDate(filterParams?.dateTo)}
                      </>
                    ) : (
                      <>
                        {
                          cuentaCorriente.headers
                            .map((header) =>
                              formatLocalDate(
                                header.settlement.settlementEvent.eventDate,
                                formatJava,
                              ),
                            )
                            .sort((d1, d2) => d1 - d2)[0]
                        }{" "}
                        {"al - " +
                          cuentaCorriente.headers
                            .map((header) =>
                              formatLocalDate(
                                header.settlement.settlementEvent.eventDate,
                                formatJava,
                              ),
                            )
                            .sort((d1, d2) => d1 - d2)[
                            cuentaCorriente.headers.length - 1
                          ]}
                      </>
                    )}
                  </span>
                </h4>
                <h4 className={`${styles.date} ${"texto"}`}>
                  {t("game")}:{" "}
                  <span className={`${styles.inputationDate} ${"texto"}`}>
                    {filterParams?.game === -1
                      ? t("all")
                      : groupByImputation(cuentaCorriente?.movements).filter(
                            (res) => (onlyFilteredGroups(res))
                          )[0]
                        ? groupByImputation(cuentaCorriente?.movements).filter(
                            (res) => onlyFilteredGroups(res),
                          )[0][0]?.gameDesc
                        : "-"}
                  </span>
                </h4>
                <h4 className={`${styles.date} ${"texto"}`}>
                  {t("concept")}:{" "}
                  <span className={`${styles.inputationDate} ${"texto"}`}>
                    {filterParams?.concept ? filterParams?.concept : t("all")}
                  </span>
                </h4>
              </Grid>
            ) : (
              <></>
            )}
            {agencieSelected.agencyLevel ===
            organization_Configuration.settlementWay ? (
              <Grid
                className="noPrint"
                justifyContent="space-between"
                alignItems="center"
                container
                item
                xs={11}
                sx={{ mt: 3 }}
              >
                {/* <Button
                  onClick={() => setOpenBank(true)}
                  className={styles.bankContainer}
                  variant="contained"
                >
                  <AccountBalanceIcon
                    sx={{ fontSize: "2rem", color: textColor }}
                  />
                  <p
                    style={{
                      margin: 0,
                      marginLeft: "10px",
                      color: textColor,
                      textTransform: "capitalize",
                      fontSize: "1.2rem",
                    }}
                  >
                    {t("banks")}
                  </p>
                </Button>
              */}
                {openBank ? (
                  <ModalCC open={openBank} closeModal={setOpenBank} />
                ) : null}
              </Grid>
            ) : (
              <></>
            )}
            {agencieSelected.agencyLevel === "CONCENTRATOR" && (
              <Grid item container xs={11} sx={{ mt: 3 }}>
                <TableContainer className={styles.tableContainer}>
                  <Table stickyHeader className="print-table">
                    <TableHead>
                      <TableCell
                        sx={{
                          backgroundColor: "primary.main",
                          color: textColor,
                        }}
                      >
                        {t("summary")}
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "primary.main",
                          color: textColor,
                        }}
                      >
                        {t("settlementDay")}
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "primary.main",
                          color: textColor,
                        }}
                      >
                        {t("expirationDate")}
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "primary.main",
                          color: textColor,
                        }}
                      >{`${t("totalDebit")}($)`}</TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "primary.main",
                          color: textColor,
                        }}
                      >{`${t("totalCredit")}($)`}</TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "primary.main",
                          color: textColor,
                        }}
                      >
                        {t("balance")}
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(cuentaCorriente?.headers) &&
                      cuentaCorriente?.headers.length ? (
                        <>
                          {cuentaCorriente?.headers?.map((item, i) => {
                            return (
                              <TableRow>
                                <TableCell>{item.settlementNumber}</TableCell>
                                <TableCell>
                                  {formatLocalDate(
                                    item.settlement.settlementEvent.eventDate,
                                    formatJava,
                                  )}
                                </TableCell>
                                <TableCell>
                                  {formatLocalDate(
                                    item.settlement.settlementEvent
                                      .debitBankExpiration1,
                                    formatJava,
                                  )}
                                </TableCell>
                                <TableCell>
                                  <Currency
                                    value={item.obligationsAmountDebit}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Currency
                                    value={item.obligationsAmountCredit}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Currency
                                    value={showSaldo(
                                      item.balanceAmountCredit,
                                      item.balanceAmountDebit,
                                    )}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </>
                      ) : (
                        <TableCell colSpan={6}>
                          <p>{t("noInfo")}</p>
                        </TableCell>
                      )}
                    </TableBody>
                    {/* <TableFooter sx={{ backgroundColor: "secondary.light" }}>
                    <TableCell
                      sx={{ fontSize: "1.5rem", fontWeight: 400 }}
                      colSpan={5}
                    >
                      {cuentaCorriente.headers?.length > 1
                        ? t("balance")
                        : message || t("noResult")}
                    </TableCell>
                    <TableCell sx={{ fontSize: "1.2rem", fontWeight: 400 }}>
                      <Currency
                        value={calculateTotalAmount(
                          cuentaCorriente?.headers || []
                        )}
                      />
                    </TableCell>
                  </TableFooter>*/}
                  </Table>
                </TableContainer>
              </Grid>
            )}

            {!print ? (
              <Grid
                item
                container
                xs={11}
                sx={{ mt: 3, justifyContent: "space-between" }}
                className={styles.optionsContainer}
              >
                <Grid
                  item
                  container
                  xs={12}
                  sx={{ justifyContent: "flex-end" }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ mr: 1, height: "2.5rem" }}
                    className={styles.optionsContainer}
                    onClick={() => {
                      setPrint(true);
                    }}
                  >
                    <PrintIcon
                      sx={{ fontSize: "1.5rem", color: textColor }}
                      className={styles.iconOptions}
                    />
                  </Button>

                  <Button
                    size="small"
                    variant="contained"
                    className={styles.optionsContainer}
                    onClick={() => excelDownload()}
                    sx={{ height: "2.5rem" }}
                  >
                    <DescriptionIcon
                      sx={{ fontSize: "1.5rem", color: textColor }}
                      className={styles.iconOptions}
                    />
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            <Grid
              sx={{ marginBottom: "1vw" }}
              justifyContent="center"
              alignItems="center"
              container
              item
            >
              <Grid
                container
                item
                xs={11}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h4 className={styles.date}>
                  {t("settlementDay")}:{" "}
                  <span className={styles.inputationDate}>
                    {formatDate(filterParams?.dateFrom)}{" "}
                    {filterParams?.dateFrom.length &&
                    filterParams?.dateTo.length
                      ? "al"
                      : "-"}{" "}
                    {formatDate(filterParams?.dateTo)}
                  </span>
                </h4>

                {cuentaCorriente?.movements?.length ? (
                  groupByImputation(
                    agencieSelected.agencyLevel === "CONCENTRATOR"
                      ? cuentaCorriente?.movements
                      : cuentaCorriente?.movements.filter(
                          (movi) =>
                            !movi.description.includes("COMISION ADICIONAL"),
                        ),
                  )
                    .filter((res) => onlyFilteredGroups(res))
                    .map((gameMovements, i) => {
                      let table = getConceptos(gameMovements).map((gm) => (
                        <>
                          <Accordion
                            sx={{
                              width: "96%",
                              marginBottom: "10px",
                              borderRadius: "10px",
                            }}
                            key={i}
                            id={"detail " + i}
                          >
                            <AccordionSummary
                              expandIcon={
                                print ? (
                                  ""
                                ) : (
                                  <ExpandMoreIcon sx={{ color: textColor }} />
                                )
                              }
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              sx={{
                                bgcolor: i % 2 === 0 ? "#e0e0e0" : "#e0e0e0",
                                borderRadius: "10px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color:
                                    textColor === "white" ? "black" : textColor,
                                  fontSize: "1rem",
                                  fontWeight: 400,
                                  width: "70%",
                                }}
                              >
                                {gm}
                              </Typography>
                              <Typography
                                sx={{
                                  color: textColor,
                                  fontSize: "1rem",
                                  width: "10rem",
                                  backgroundColor:
                                    textColor === "black" ? "white" : "",
                                  borderRadius: "10px",
                                  padding: "5px",
                                  textAlign: "center",
                                }}
                              >
                                {t("subtotal")}:{" "}
                                <span
                                  style={{
                                    fontSize: "1rem",
                                    fontWeight:
                                      textColor === "black" ? "bold" : 400,
                                  }}
                                >
                                  <Currency
                                    value={gameMovements
                                      .filter((move) =>
                                        filterParams?.concept !== ""
                                          ? move.description ===
                                            filterParams.concept
                                          : move,
                                      )
                                      .filter((mv) => mv.description.includes("COMISION") || mv.description.includes("CSA")
                                      ? mv.description.includes(gm)
                                      : mv.description.replace(/ /g, "") === gm.replace(/ /g, ""),
                                      )
                                      .reduce((acc, b) => acc + b.amount, 0)}
                                  />
                                </span>
                              </Typography>
                            </AccordionSummary>

                            <TableContainer
                              sx={{
                                m: 1.5,
                                maxHeight: "97vh",
                                width: "98%",
                                borderRadius: "10px 10px 10px 10px",
                              }}
                              color={"primary"}
                              key={i}
                            >
                              <Table stickyHeader className={`print-table`}>
                                <TableHead sx={{ bgcolor: "secondary.light" }}>
                                  {print ? (
                                    <TableRow>
                                      <TableCell
                                        sx={{
                                          fontSize: "0.9em",
                                          fontWeight: 600,
                                        }}
                                        colSpan={4}
                                      >
                                        {gameMovements[0].gameDesc}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          fontSize: "0.9em",
                                          fontWeight: 600,
                                        }}
                                      >
                                        Subtotal:{" "}
                                        {
                                          <Currency
                                            value={gameMovements
                                              .filter((move) =>
                                                filterParams?.concept !== ""
                                                  ? move.description ===
                                                    filterParams.concept
                                                  : move,
                                              )
                                              .reduce(
                                                (acc, b) => acc + b.amount,
                                                0,
                                              )}
                                          />
                                        }
                                      </TableCell>
                                    </TableRow>
                                  ) : (
                                    <></>
                                  )}
                                  <TableRow color="primary.main">
                                    <TableCell
                                      sx={{
                                        fontSize: "0.9em",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {t("settlementDay")}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        fontSize: "0.9em",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {t("imputationDate")}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        fontSize: "0.9em",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {t("concept")}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        fontSize: "0.9em",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {t("type")}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        fontSize: "0.9em",
                                        fontWeight: 600,
                                      }}
                                      align="right"
                                    >
                                      {t("balance")}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>

                                <TableBody sx={{ maxHeight: "15vh" }}>
                                  {Array.isArray(gameMovements) &&
                                    gameMovements
                                      .filter((move) =>
                                        filterParams?.concept !== ""
                                          ? move.description ===
                                            filterParams.concept
                                          : move,
                                      )
                                      .filter((mv) => mv.description.includes("COMISION") || mv.description.includes("CSA")
                                      ? mv.description.includes(gm)
                                      : mv.description.replace(/ /g, "") === gm.replace(/ /g, ""),
                                      )
                                      .map((movement, i) => (
                                        <TableRow
                                          sx={{
                                            bgcolor: "#FDFDFD",
                                            borderBottom:
                                              "1px solid rgba(0, 0, 0, 0.455)",
                                          }}
                                          hover
                                        >
                                          {createCtaCteRow(
                                            formatLocalDate(
                                              movement.imputationDate,
                                              formatJavaCompleto,
                                            ),
                                            true,
                                            cellStyles,
                                            false,
                                          )}
                                          {createCtaCteRow(
                                            formatLocalDate(
                                              movement.created,
                                              formatJavaCompleto,
                                            ),
                                            true,
                                            cellStyles,
                                            false,
                                          )}
                                          {createCtaCteRow(
                                            movement.description,
                                            true,
                                            cellStyles,
                                            false,
                                          )}
                                          {createCtaCteRow(
                                            translateDebitCredit(movement.tipo),
                                            true,
                                            cellStyles,
                                            false,
                                          )}
                                          {createCtaCteRowMoney(
                                            <Currency
                                              value={movement.amount}
                                            />,
                                            true,
                                            cellStyles,
                                            false,
                                          )}
                                        </TableRow>
                                      ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Accordion>{" "}
                        </>
                      ));

                      return gameMovements.filter((move) =>
                        filterParams?.concept !== ""
                          ? move.description === filterParams.concept
                          : move,
                      ).length > 0 ? (
                        print ? (
                          <>{table}</>
                        ) : (
                          <Accordion
                            sx={{
                              width: "100%",
                              marginBottom: "10px",
                              borderRadius: "10px",
                            }}
                            key={i}
                            id={` ${i}`}
                          >
                            <AccordionSummary
                              expandIcon={
                                <ExpandMoreIcon sx={{ color: textColor }} />
                              }
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              sx={{
                                bgcolor:
                                  i % 2 === 0
                                    ? "primary.main"
                                    : "primary.light",
                                borderRadius: "10px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: textColor,
                                  fontSize: "1rem",
                                  fontWeight: 400,
                                  width: "70%",
                                }}
                              >
                                {gameMovements[0].gameDesc}
                              </Typography>
                              <Typography
                                sx={{
                                  color:
                                    textColor === "white" ? "black" : textColor,
                                  fontSize: "1rem",
                                  width: "10rem",
                                  backgroundColor: "white",
                                  borderRadius: "10px",
                                  padding: "5px",
                                  textAlign: "center",
                                }}
                              >
                                {t("subtotal")}:{" "}
                                <span
                                  style={{
                                    fontSize: "1rem",
                                    fontWeight:
                                      textColor === "black" ? "bold" : 400,
                                  }}
                                >
                                  <Currency
                                    value={gameMovements
                                      .filter((move) =>
                                        filterParams?.concept !== ""
                                          ? move.description ===
                                            filterParams.concept
                                          : move,
                                      )
                                      .reduce((acc, b) => acc + b.amount, 0)}
                                  />
                                </span>
                              </Typography>
                            </AccordionSummary>

                            <AccordionDetails
                              sx={{ padding: 0, textAlign: "-webkit-center" }}
                            >
                              {table}
                            </AccordionDetails>
                          </Accordion>
                        )
                      ) : (
                        <></>
                      );
                    })
                ) : (
                  <Grid item xs={12} className={styles.noResultContainer}>
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "1.8rem",
                        fontWeight: 300,
                        height: "200px",
                      }}
                    >
                      {t("noResults")}
                    </p>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <ModalProviders
              open={openFilter}
              setOpen={setOpenFilter}
              setFilters={setFilterParams}
              filters={filterParams}
              setSearching={setSearching}
              setSearch={setSearch}
              cuentaCorriente={cuentaCorriente}
              setSearchDay={setSearchDay}
              dateFrom={dateFrom}
              dateTo={dateTo}
              setDateFrom={setDateFrom}
              setDateTo={setDateTo}
              diaHoy={diaHoy}
            />
          </Grid>
        </>
      )}
      {loading ? (
        <></>
      ) : (
        cuentaCorriente?.movements?.length > 0 &&
        filterParams.dateFrom === filterParams.dateTo && (
          <Grid
            container
            xs={11}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              borderRadius: "15px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "45%",
                bgcolor: "primary.dark",
                borderRadius: "10px",
                padding: "20px 30px",
              }}
            >
              <Typography
                sx={{
                  color: textColor === "black" ? "white" : textColor,
                  fontSize: "1rem",
                  fontWeight: 400,
                  width: "37%",
                }}
              >
                TOTAL:
              </Typography>
              <Typography
                sx={{
                  color: textColor,
                  fontSize: "1rem",
                  fontWeight: "bold",
                  width: "40%",
                  backgroundColor: textColor === "black" ? "white" : "",
                  borderRadius: "10px",
                  padding: "5px",
                  textAlign: "center",
                  marginRight: "auto",
                  alignSelf: "flex-start",
                }}
              >
                <Currency
                  value={groupByImputation(cuentaCorriente?.movements)
                    .filter((res) => onlyFilteredGroups(res))
                    .map((gameMovements) =>
                      gameMovements
                        .filter((move) =>
                          filterParams?.concept !== ""
                            ? move.description === filterParams.concept
                            : move,
                        )
                        .reduce((acc, b) => acc + b.amount, 0),
                    )
                    .reduce((acc, b) => acc + b, 0)}
                />
              </Typography>
            </Box>
          </Grid>
        )
      )}
    </Grid>
  );
};
